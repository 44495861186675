import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon
} from "@mui/icons-material";
import { Box, DialogContent, Grid, Link, Stack } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useDownloadURL } from "react-firebase-hooks/storage";

import SkeletonCandidateProfile from "@skeletons/SkeletonCandidateProfile";

import Avatar from "@components/Avatar";
import Button from "@components/Button";
import Dialog from "@components/Dialog";
import DownloadFilesAsZipButton from "@components/DownloadFilesAsZipButton";
import Icon from "@components/Icon";
import InformationField from "@components/InformationField";
import { commonFavoriteIconStyle } from "@components/JobCards/JobCard";
import JobExperienceOverviewCard from "@components/JobExperienceOverviewCard";
import LanguageCard from "@components/LanguageCard";
import Paper from "@components/Paper";
import PdfViewerModal from "@components/PdfViewerModal";
import PdfViewerThumbnail from "@components/PdfViewerThumbnail";
import SkillsCard from "@components/SkillsCard";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import UserProfile from "@interfaces/database/UserProfile";
import CandidateDetails from "@interfaces/functions/CandidateDetails";

import { handleCandidateBookmarkChange } from "@utils/bookmarkCandidate";
import {
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV,
  FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO,
  FILES_LOCATION_INDIVIDUAL_EN_CV,
  FILES_LOCATION_INDIVIDUAL_JA_CV,
  FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO,
  FIRESTORE_COLLECTIONS,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  LOCALE,
  USER_TYPE
} from "@utils/config";
import { auth, db, functions, storage } from "@utils/firebase";
import { getCountryName } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import { isCandidateProfilePurchased } from "@utils/purchaseResume";
import { roundSalary } from "@utils/roundSalary";
import theme from "@utils/theme";
import translate, { intl } from "@utils/translate";

import VisaIcon from "@assets/images/Visa.svg";

interface PdfThumbnailWithModalProps {
  pdfURL: string;
  pdfName?: string;
}

const PdfThumbnailWithModal = ({
  pdfURL,
  pdfName
}: PdfThumbnailWithModalProps) => {
  return (
    <Stack alignItems="center" gap={1}>
      <Dialog
        showCloseBtn={false}
        initiator={<PdfViewerThumbnail pdfURL={pdfURL} />}
        maxWidth="lg"
        height="80vh">
        {(_, handleCancel) => (
          <DialogContent>
            <PdfViewerModal
              pdfURL={pdfURL}
              pdfName={pdfName}
              handleClose={handleCancel}
            />
          </DialogContent>
        )}
      </Dialog>
      <Link underline="none" href={pdfURL}>
        <Button variant="text" startAdornment={<Icon type="download" />}>
          {intl.get("t_general_download")}
        </Button>
      </Link>
    </Stack>
  );
};

const CandidateProfile = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { candidate_id } = useParams();
  const { pathname } = useLocation();
  const [getCandidateDetails] = useHttpsCallable(
    functions,
    "getCandidateDetails"
  );
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const companyDetails = useCompanyDetails();
  const [candidateDetails, setCandidateDetails] = useState<CandidateDetails>({
    name: { en: "" },
    initials: { en: "" },
    current_job_title: { en: "" },
    is_masked_info: true
  });
  const [candidate, setCandidate] = useState<UserProfile | null>(null);
  const bookmarkedCandidatesData = companyDetails.value?.bookmarked_candidates;
  const [bookmarkedCandidates, setBookmarkedCandidates] = useState<
    Record<string, string>
  >({});
  const [isBookmarkedCandidatesLoading, setIsBookmarkedCandidatesLoading] =
    useState<boolean>(false);
  const [isResumePurchased, setIsResumePurchased] = useState<boolean>(false);
  const [fileStates, setFileStates] = useState<{
    [key: string]: {
      url: string;
    };
  }>({
    enCv: { url: "" },
    jaCv: { url: "" },
    jaRirekisho: { url: "" }
  });

  const profilePicUrl = candidateDetails?.photo_url ?? "";
  const basicInformation = candidate?.summary?.basic_information;
  const candidateSkills = candidate?.summary?.skills ?? [];
  const jobExperienceOverviews =
    candidate?.summary?.job_experience_overview ?? [];

  const companyId = userProfile.value?.company_id;
  const isIndividualUserType =
    userProfile.value?.user_type === USER_TYPE.INDIVIDUAL;

  useEffect(() => {
    if (candidate_id && companyId) {
      (async () => {
        try {
          const result = await getCandidateDetails({
            companyId,
            candidateIds: [candidate_id]
          });
          const candidateDetails = result?.data as Record<
            string,
            CandidateDetails
          >;
          const singleCandidateDetail = candidateDetails[candidate_id];
          setCandidateDetails(singleCandidateDetail);
        } catch (error) {
          toast.kampai(intl.get("t_toast_fetch_candidate_data_error"), "error");
        }
      })();
    }
  }, [candidate_id, companyId]);

  const fetchUserDetails = async (candidateId: string) => {
    try {
      const userRef = doc(db, FIRESTORE_COLLECTIONS.USERS, candidateId);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.data()) {
        setCandidate(userSnapshot.data() as UserProfile);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  useEffect(() => {
    if (candidate_id) {
      fetchUserDetails(candidate_id);
    }
  }, [candidate_id]);

  useEffect(() => {
    if (user && !isIndividualUserType && bookmarkedCandidatesData) {
      setIsBookmarkedCandidatesLoading(true);
      const newBookmarkedCandidates: Record<string, string> = {};
      for (const key in bookmarkedCandidatesData) {
        const candidateId = bookmarkedCandidatesData[key].candidate_id;
        newBookmarkedCandidates[candidateId] = key;
      }
      setBookmarkedCandidates(newBookmarkedCandidates);
      setIsBookmarkedCandidatesLoading(false);
    }
  }, [user, isIndividualUserType, bookmarkedCandidatesData]);

  const handleError = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    navigate(`/${translate.getCurrentLocale()}/not-found`);
  };

  const firstName =
    resolveMultiLingual(basicInformation?.first_name, LOCALE.EN, true) ?? "";
  const lastName =
    resolveMultiLingual(basicInformation?.last_name, LOCALE.EN, true) ?? "";
  const fullName = `${lastName} ${firstName}`.trim();

  const visaType = basicInformation?.immigration_details?.visa_type;
  const countryOfOrigin = basicInformation?.immigration_details?.country;
  const noOfCompanies = basicInformation?.number_of_companies;
  const expectedSalary = basicInformation?.expected_salary;
  const noJobExperience = candidate?.summary?.no_job_experience_overview;
  const languages = candidate?.summary?.languages ?? [];
  const isMaskedInfo = candidateDetails?.is_masked_info ?? true;
  const userCountryOfOrigin = countryOfOrigin
    ? getCountryName(
        countryOfOrigin,
        translate.getLocaleShort(intl.getInitOptions().currentLocale)
      )
    : "";
  const bookmarkedCandidateDocId = candidate_id
    ? bookmarkedCandidates?.[candidate_id]
    : "";
  const isCandidateBookmarked = bookmarkedCandidateDocId ? true : false;

  const handleCandidateBookmark = () => {
    if (user && userProfile.value?.company_id && candidate_id) {
      const updatedBookmarkedCandidates = {
        ...bookmarkedCandidates
      };

      if (isCandidateBookmarked) {
        // Remove candidate_id from bookmarkedCandidates
        delete updatedBookmarkedCandidates[candidate_id];
        setBookmarkedCandidates(updatedBookmarkedCandidates);
      } else {
        // Add candidate_id to bookmarkedCandidates
        updatedBookmarkedCandidates[candidate_id] = "";
        setBookmarkedCandidates(updatedBookmarkedCandidates);
      }

      (async () => {
        if (user && userProfile.value?.company_id) {
          // Fetch the document id for the new bookmark and update the state
          const newBookmarkedCandidateDocId =
            await handleCandidateBookmarkChange(
              userProfile?.value.company_id,
              candidate_id,
              user?.uid,
              userProfile,
              companyDetails,
              isCandidateBookmarked,
              bookmarkedCandidateDocId
            );

          if (newBookmarkedCandidateDocId) {
            const updatedBookmarkedCandidates = {
              ...bookmarkedCandidates
            };
            updatedBookmarkedCandidates[candidate_id] =
              newBookmarkedCandidateDocId;
            setBookmarkedCandidates(updatedBookmarkedCandidates);
          }
        }
      })();
    }
  };

  // Job Overview
  const jobExperienceOverviewCardProps = !noJobExperience
    ? jobExperienceOverviews?.map((singleJobOverview) => {
        return {
          jobTitle: resolveMultiLingual(singleJobOverview.job_title) ?? "",
          yearsOfExperience: singleJobOverview.years_of_experience
        };
      })
    : [];

  // Language
  const languageCardProps = languages?.map((singleLanguage) => {
    return {
      name: singleLanguage?.name,
      proficiency: singleLanguage?.proficiency,
      testName: singleLanguage?.test_name,
      testResult: singleLanguage?.test_result
    };
  });

  // Skills
  const skillCardProps = candidateSkills?.map((singleSkill) => {
    return {
      name: singleSkill?.name,
      yearsOfExperience: singleSkill?.years_of_experience
    };
  });

  const CurrentFavoriteIcon = () =>
    isCandidateBookmarked ? (
      <FavoriteIcon
        color="error"
        sx={commonFavoriteIconStyle}
        onClick={handleCandidateBookmark}
      />
    ) : (
      <FavoriteBorderIcon
        color="error"
        sx={commonFavoriteIconStyle}
        onClick={handleCandidateBookmark}
      />
    );

  const handleResumePurchaseChecks = async () => {
    if (companyId && candidate_id) {
      const purchaseStatus = await isCandidateProfilePurchased(
        candidate_id,
        userProfile,
        companyId
      );
      setIsResumePurchased(purchaseStatus);
    }
  };

  useEffect(() => {
    handleResumePurchaseChecks();
  }, [companyId, candidate_id]);

  // Download URLs and File Information for English and Japanese CVs and Rirekisho

  const enCVFileInfo = resolveMultiLingual(
    candidate?.cv?.file_information,
    LOCALE.EN,
    true
  );
  const jaCVFileInfo = resolveMultiLingual(
    candidate?.cv?.file_information,
    LOCALE.JA,
    true
  );
  const jaRirekishoFileInfo = candidate?.ja_rirekisho?.file_information;

  const getCandidateFullName = () => {
    let candidateFullName = "";
    if (candidate?.summary?.basic_information?.first_name) {
      candidateFullName += resolveMultiLingual(
        candidate?.summary?.basic_information?.first_name
      );
    }
    if (candidate?.summary?.basic_information?.last_name) {
      candidateFullName += ` ${resolveMultiLingual(
        candidate?.summary?.basic_information?.last_name
      )}`;
    }
    candidateFullName = candidateFullName.trim();
    if (!candidateFullName) {
      candidateFullName = intl.get("t_resumes");
    }
    return candidateFullName;
  };

  const candidateFullName = getCandidateFullName();

  const fileInformation = [
    {
      name: `CV${candidateFullName ? " " + candidateFullName : ""}`,
      path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV}/${candidate_id}.pdf`,
      extension: "pdf"
    },
    {
      name: `職務経歴書${candidateFullName ? " " + candidateFullName : ""}`,
      path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV}/${candidate_id}.pdf`,
      extension: "pdf"
    },
    {
      name: `履歴書${candidateFullName ? " " + candidateFullName : ""}`,
      path: `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO}/${candidate_id}.pdf`,
      extension: "pdf"
    }
  ];

  if (enCVFileInfo?.name && enCVFileInfo?.extension) {
    fileInformation.push({
      name: enCVFileInfo.name,
      path: `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${candidate_id}.${enCVFileInfo.extension}`,
      extension: enCVFileInfo.extension
    });
  }

  if (jaCVFileInfo?.name && jaCVFileInfo?.extension) {
    fileInformation.push({
      name: jaCVFileInfo.name,
      path: `${FILES_LOCATION_INDIVIDUAL_JA_CV}/${candidate_id}.${jaCVFileInfo.extension}`,
      extension: jaCVFileInfo.extension
    });
  }

  if (jaRirekishoFileInfo?.name && jaRirekishoFileInfo?.extension) {
    fileInformation.push({
      name: jaRirekishoFileInfo.name,
      path: `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${candidate_id}.${jaRirekishoFileInfo.extension}`,
      extension: jaRirekishoFileInfo.extension
    });
  }

  const fetchDownloadURL = async (filePath: string, fileType: string) => {
    try {
      const url = await getDownloadURL(ref(storage, filePath));
      setFileStates((prevState) => ({
        ...prevState,
        [fileType]: { ...prevState[fileType], url }
      }));
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  useEffect(() => {
    const enCVPath = enCVFileInfo?.extension
      ? `${FILES_LOCATION_INDIVIDUAL_EN_CV}/${candidate_id}.${enCVFileInfo.extension}`
      : "";
    const jaCVPath = jaCVFileInfo?.extension
      ? `${FILES_LOCATION_INDIVIDUAL_JA_CV}/${candidate_id}.${jaCVFileInfo.extension}`
      : "";
    const jaRirekishoPath = jaRirekishoFileInfo?.extension
      ? `${FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO}/${candidate_id}.${jaRirekishoFileInfo.extension}`
      : "";

    if (enCVPath) {
      fetchDownloadURL(enCVPath, "enCv");
    }
    if (jaCVPath) {
      fetchDownloadURL(jaCVPath, "jaCv");
    }
    if (jaRirekishoPath) {
      fetchDownloadURL(jaRirekishoPath, "jaRirekisho");
    }
  }, [
    candidate_id,
    enCVFileInfo?.extension,
    jaCVFileInfo?.extension,
    jaRirekishoFileInfo?.extension
  ]);

  const [downloadAutoGeneratedEnCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV}/${candidate_id}.pdf`
    )
  );
  const [downloadAutoGeneratedJaCvUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV}/${candidate_id}.pdf`
    )
  );
  const [downloadAutoGeneratedJaRirekishoUrl] = useDownloadURL(
    ref(
      storage,
      `${FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO}/${candidate_id}.pdf`
    )
  );

  return (
    <Paper>
      {isBookmarkedCandidatesLoading ? (
        <SkeletonCandidateProfile />
      ) : (
        <>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
            gap={2}
            mb={1}>
            {/* User Details */}
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
              mb={2}>
              <Avatar imgSrc={profilePicUrl} fullName={fullName} />
              <Box>
                <Stack direction="row" gap={2}>
                  <Typography
                    variant="subtitle2"
                    sx={
                      isMaskedInfo
                        ? {
                            filter: "blur(4px)",
                            userSelect: "none"
                          }
                        : null
                    }>
                    {isMaskedInfo
                      ? intl.get(
                          "t_employer_resume_purchase_table_candidate_name"
                        )
                      : fullName ?? "-"}
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                  textTransform="capitalize">
                  {resolveMultiLingual(candidateDetails.current_job_title)}
                </Typography>
              </Box>
            </Stack>
            {/* action buttons start */}
            <Stack ml="auto" direction="row" alignItems="center" gap={2} mb={2}>
              {isResumePurchased && (
                <DownloadFilesAsZipButton
                  filesInformation={fileInformation}
                  zipFileName={intl.get("t_resumes")}
                  btnLabel={intl.get("t_resumes_download_button_label")}
                />
              )}
              {user && !isIndividualUserType && <CurrentFavoriteIcon />}
              {!user && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  handleClick={() => {
                    navigate(
                      `/${translate.getCurrentLocale()}/employers?login_required=1&mode=sign-up&return_url=${pathname}`
                    );
                  }}>
                  {intl.get("t_general_no_signup_cta")}
                </Button>
              )}
            </Stack>
            {/* action buttons end */}
          </Stack>

          <Stack gap={2}>
            {/* Personal Information */}
            <Typography variant="h4" width="max-content">
              {intl.get("t_resumes_basic_info_title")}
            </Typography>
            <Stack direction="column" gap={1}>
              <InformationField
                icon="email"
                label={
                  isMaskedInfo
                    ? intl.get("t_general_email_address")
                    : candidateDetails?.email ?? ""
                }
                blurText={isMaskedInfo}
              />
              <InformationField
                icon="phone_enabled"
                label={
                  isMaskedInfo
                    ? intl.get("t_general_phone_number")
                    : candidateDetails?.phone_number ?? ""
                }
                blurText={isMaskedInfo}
              />
              <InformationField icon="home" label={userCountryOfOrigin} />
              {visaType ? (
                <InformationField
                  imgSrc={VisaIcon}
                  imgAlt={intl.get("t_alt_visa_types")}
                  label={
                    visaType
                      ? intl.get(INDIVIDUAL_USER_VISA_TYPE_T_LABELS[visaType])
                      : "-"
                  }
                />
              ) : (
                false
              )}
              {!noJobExperience ? (
                <InformationField
                  icon="apartment"
                  label={`${
                    noOfCompanies
                      ? intl.get("t_resumes_basic_info_companies", {
                          noOfCompanies
                        })
                      : "-"
                  } `}
                />
              ) : (
                false
              )}
              <InformationField
                icon="currency_yen"
                label={
                  expectedSalary
                    ? intl.get("t_resumes_basic_info_rounded_salary", {
                        expectedSalary: roundSalary(expectedSalary)
                      })
                    : "-"
                }
              />
            </Stack>

            {/* Experience */}
            <Typography variant="h4">
              {intl.get("t_profile_summary_experience")}
            </Typography>
            {noJobExperience ? (
              <Typography variant="body1">
                {intl.get("t_general_fresher")}
              </Typography>
            ) : (
              <Grid container gap={1}>
                {jobExperienceOverviewCardProps?.map(
                  (singleJobOverview, index) => (
                    <Grid item xs={12} md={2.8} key={index}>
                      <JobExperienceOverviewCard {...singleJobOverview} />
                    </Grid>
                  )
                )}
              </Grid>
            )}

            {/* Language */}
            {languageCardProps.length > 0 ? (
              <>
                <Typography variant="h4">
                  {intl.get("t_resumes_language_title")}
                </Typography>
                <Grid container gap={1}>
                  {languageCardProps?.map((singleLanguage, index) => (
                    <Grid item xs={12} md={2.8} key={index}>
                      <LanguageCard {...singleLanguage} />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              false
            )}

            {/* Skills */}
            {skillCardProps.length > 0 ? (
              <>
                <Typography variant="h4">
                  {intl.get("t_resumes_skills_title")}
                </Typography>
                <SkillsCard skills={skillCardProps} />
              </>
            ) : (
              false
            )}

            {/* Documents */}
            {isResumePurchased && (
              <>
                <Typography variant="h4">
                  {intl.get("t_general_english_cv")}
                </Typography>
                {fileStates.enCv.url || downloadAutoGeneratedEnCvUrl ? (
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems="flex-start"
                    my={1}
                    gap={3}
                    width="100%">
                    {downloadAutoGeneratedEnCvUrl ? (
                      <PdfThumbnailWithModal
                        pdfURL={downloadAutoGeneratedEnCvUrl}
                        pdfName="EnglishCV.pdf" // FIXME: Update name once it is available for auto generated resume
                      />
                    ) : (
                      false
                    )}

                    {fileStates.enCv.url ? (
                      <PdfThumbnailWithModal
                        pdfURL={fileStates.enCv.url}
                        pdfName={`${enCVFileInfo?.name}.${enCVFileInfo?.extension}`}
                      />
                    ) : (
                      false
                    )}
                  </Stack>
                ) : (
                  <Typography variant="subtitle3" color="text.secondary">
                    {intl.get("t_resumes_not_uploaded", {
                      resume: intl.get("t_general_english_cv")
                    })}
                  </Typography>
                )}

                <Typography variant="h4">
                  {intl.get("t_general_japanese_cv")}
                </Typography>
                {fileStates.jaCv.url || downloadAutoGeneratedJaCvUrl ? (
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems="flex-start"
                    my={1}
                    gap={3}
                    width="100%">
                    {downloadAutoGeneratedJaCvUrl ? (
                      <PdfThumbnailWithModal
                        pdfURL={downloadAutoGeneratedJaCvUrl}
                        pdfName="JapaneseCV.pdf" // FIXME: Update name once it is available for auto generated resume
                      />
                    ) : (
                      false
                    )}

                    {fileStates.jaCv.url ? (
                      <PdfThumbnailWithModal
                        pdfURL={fileStates.jaCv.url}
                        pdfName={`${jaCVFileInfo?.name}.${jaCVFileInfo?.extension}`}
                      />
                    ) : (
                      false
                    )}
                  </Stack>
                ) : (
                  <Typography variant="subtitle3" color="text.secondary">
                    {intl.get("t_resumes_not_uploaded", {
                      resume: intl.get("t_general_japanese_cv")
                    })}
                  </Typography>
                )}

                <Typography variant="h4">
                  {intl.get("t_rirekisho_title")}
                </Typography>
                {fileStates.jaRirekisho.url ||
                downloadAutoGeneratedJaRirekishoUrl ? (
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems="flex-start"
                    my={1}
                    gap={3}
                    width="100%">
                    {downloadAutoGeneratedJaRirekishoUrl ? (
                      <PdfThumbnailWithModal
                        pdfURL={downloadAutoGeneratedJaRirekishoUrl}
                        pdfName="Rirekisho.pdf" // FIXME: Update name once it is available for auto generated resume
                      />
                    ) : (
                      false
                    )}

                    {fileStates.jaRirekisho.url ? (
                      <PdfThumbnailWithModal
                        pdfURL={fileStates.jaRirekisho.url}
                        pdfName={`${jaRirekishoFileInfo?.name}.${jaRirekishoFileInfo?.extension}`}
                      />
                    ) : (
                      false
                    )}
                  </Stack>
                ) : (
                  <Typography variant="subtitle3" color="text.secondary">
                    {intl.get("t_resumes_not_uploaded", {
                      resume: intl.get("t_rirekisho_title")
                    })}
                  </Typography>
                )}
              </>
            )}
          </Stack>
        </>
      )}
    </Paper>
  );
};

export default CandidateProfile;
